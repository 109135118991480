.normal_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 32px;
  gap: 10px;
  height: 58px;
  background: linear-gradient(180deg, #fd572a 0%, #f0951d 100%);
  border-radius: 16px;
  font-weight: 700;
  font-size: 14px;
  line-height: 26px;
  color: #ffffff;
  border: none;
  width: 100%;
  cursor: pointer;
}
