.contact_us {
  width: 100%;
  margin-bottom: 65px;
  .contact_us_form {
    display: flex;
    flex-direction: column;
    gap: 24px;
    .btn_c {
      & > button {
        display: flex;

        justify-content: flex-start;
        max-width: 340px;
      }
    }
  }
  .title_text {
    margin-bottom: 24px;
  }
}
