@use "./components/Filter";
@use "./components/Products/Products";
@use "./components/ProductsNav/ProductsNav";
@use "./components/Contact/contact";
.home_page {
  // min-height: 100vh;

  .search {
    background: linear-gradient(180deg, #fd572a 0%, #f0951d 100%);
    height: 96px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 70px;
    @media screen and (max-width: 1091px) {
      background: none;
      justify-content: space-between;
      padding: 24px;
      margin-bottom: 0px;
      gap: 20px;
      .menu_filter {
        width: 40.38px;
        height: 39.33px;
        background: linear-gradient(180deg, #fd572a 0%, #f0951d 100%);
        border-radius: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 4px;
        cursor: pointer;
      }
    }

    .search_input {
      max-width: 620px;
      border-radius: 10px;
      background: #ffffff;
      border: 1px solid #868889;
      border-radius: 18px;
    }
  }
  .content_home {
    padding: 0 24px;
    display: grid;
    grid-gap: 11px;
    grid-template-columns: repeat(24, minmax(auto, calc(100% / 24)));
    .filter_section {
      grid-column-end: span 5;
      background: #fff1de;
      border-radius: 8px;
      padding: 24px;
      height: 100%;
      min-height: 400px;
      min-width: 300px;
    }
    .products_section {
      grid-column-end: span 19;
      @media screen and (max-width: 1091px) {
        grid-column-end: span 24;
      }
    }
  }
}
