.s_input {
  label {
    font-weight: 700;
    font-size: 14px;
    line-height: 26px;
    color: #000000;
    margin-bottom: 8px;
  }
  .input_antd {
    height: 44px;
    background: #ffffff;
    border: 1px solid #868889;
    border-radius: 18px;
    input {
      &::placeholder {
        font-family: "Cairo";
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 30px;
      }
    }
  }
  .ant-select-show-search {
    z-index: 10;
    height: 44px;
    background: #ffffff;
    border: 1px solid #d0d5dd;
    border-radius: 8px;
    border: none;
    & > div {
      height: 44px;
      background: #ffffff;
      border: 1px solid #d0d5dd;
      border-radius: 8px;
    }
  }
  .ant-select-dropdown.css-dev-only-do-not-override-ixblex.ant-select-dropdown-empty.ant-select-dropdown-placement-topLeft {
    z-index: 10 !important;
  }
}

.error_txt {
  color: red;
  font-size: 11px;
  // margin-bottom: 12px;
  // margin-bottom: -10px;
}
