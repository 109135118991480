.fill_info {
  //   min-height: calc(100vh - 270px);
  padding: 30px 250px 60px 250px;
  width: 100%;
  @media only screen and (max-width: 1300px) {
    padding: 30px 60px 60px 60px;
    width: 100%;
  }
  @media only screen and (max-width: 919px) {
    padding: 30px 30px 60px 30px;

    width: 100%;
  }
  @media only screen and (max-width: 700px) {
    padding: 20px 20px 50px 20px;
  }
  @media only screen and (max-width: 550px) {
    display: block;
  }
  .profile_content {
    max-width: 696px;

    width: 100%;
    .title {
      font-weight: 700;
      font-size: 24px;
      line-height: 45px;
      color: #000000;
      width: 100%;
      margin-bottom: 20px;
      @media only screen and (max-width: 550px) {
        margin-bottom: 0 !important;
      }
    }
    .profile_details {
      width: 100%;
      display: grid;
      justify-content: center;
      //   flex-direction: column;
      grid-gap: 16px;
      grid-template-columns: repeat(12, minmax(auto, calc(100% / 12)));
      margin-bottom: 16px;
      .input_profile {
        grid-column-end: span 6;
        max-width: 340px;
        min-width: 340px;
        @media only screen and (max-width: 740px) {
          //   grid-column-end: span 12;
          width: 100%;
          max-width: 100%;
          min-width: 100%;
        }
        @media only screen and (max-width: 550px) {
          grid-column-end: span 12;
          width: 100%;
          max-width: 100%;
          min-width: 100%;
        }
      }
    }
    .btn_edit {
      width: 49%;
      margin-bottom: 56px;
      @media only screen and (max-width: 550px) {
        width: 100%;
      }

      button {
        width: 100%;
        cursor: pointer;
      }
    }
  }
  .checkbox_input {
    margin-bottom: 9px;
    user-select: none;
    .action_term {
      font-weight: 700;
      font-size: 14px;
      line-height: 26px;
      text-decoration-line: underline;
      background: linear-gradient(180deg, #fd572a 0%, #f0951d 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      user-select: none;
    }
  }
  .text2 {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 30px;
    color: #868889;
    margin-bottom: 20px;
  }
}
