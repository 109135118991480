@use "./components/ContactUs/ContactUs";
.about_page {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  flex-direction: column;
  padding: 60px 133px 0px 133px;
  @media only screen and (max-width: 1250px) {
    padding: 20px 40px;
  }
  @media only screen and (max-width: 750px) {
    padding: 20px;
  }
  .about_text1 {
    font-weight: 700;
    font-size: 40px;
    line-height: 75px;
    text-align: center;
    color: #000000;
    @media only screen and (max-width: 750px) {
      ont-weight: 700;
      font-size: 16px;
      line-height: 30px;
    }
  }
  .about_text2 {
    font-weight: 700;
    font-size: 40px;
    line-height: 75px;
    text-align: center;
    color: #000000;
    @media only screen and (max-width: 750px) {
      font-weight: 700;
      font-size: 16px;
      line-height: 30px;
    }
  }
  .img_phones {
    margin-top: -150px;
    width: 100%;
    height: 100%;
    max-width: 900px;
    @media only screen and (max-width: 750px) {
      margin-top: -50px;
    }
  }
  .section_texts {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 30px;
    // padding: 0 20px;
    margin-bottom: 50px;
    @media only screen and (max-width: 660px) {
      flex-direction: column;
      margin-bottom: 30px;
    }

    .title {
      font-weight: 700;
      font-size: 36px;
      line-height: 67px;
      color: #000000;
      margin-bottom: 7px;
      @media only screen and (max-width: 890px) {
        font-weight: 700;
        font-size: 24px;
        line-height: 27px;
      }
    }
    .text {
      font-weight: 400;
      font-size: 16px;
      line-height: 30px;
      display: flex;
      color: #000000;

      @media only screen and (max-width: 890px) {
        font-weight: 400;
        font-size: 14px;
        line-height: 26px;
      }
    }
    .left {
      max-width: 418px;
      @media only screen and (max-width: 660px) {
        max-width: 100%;
      }
    }
    .right {
      max-width: 418px;
      @media only screen and (max-width: 660px) {
        max-width: 100%;
      }
    }
  }
  .contact_us_action {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    align-items: flex-start;
    .sub_title {
      font-weight: 600;
      font-size: 24px;
      line-height: 45px;
      color: #000000;
    }
  }
}
