.select {
  position: relative;
  width: 100% !important;
  max-width: 330px !important;
  & > div {
    width: 100%;
    max-width: 100%;
    //       min-height: 44px;
    //       background: #ffffff;
    //       border: none;
    //       border-radius: 18px;
  }

  .ant-select-show-search {
    //     z-index: 10;

    width: 100%;
    & > div {
      width: 100%;
    }
  }
  .ant-select-selector {
    padding: 7px !important;
    background: #ffffff;
    border: 1px solid #868889 !important;
    border-radius: 18px;
    height: none;
  }
  .ant-select-selection-placeholder {
    margin-left: 30px;
  }
  .ant-select-selection-overflow {
    padding-left: 30px;
  }
  .icon_prefix {
    position: absolute;
    left: 9px;
    z-index: 5;
    top: 4px;
  }

  .select_item {
    .ant-select-selector {
      height: 44px !important;
    }
    .ant-select-selection-item {
      padding-left: 30px !important;
    }
    .ant-select-selection-placeholder {
      margin-left: 30px !important;
    }
  }
}
