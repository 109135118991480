.phone_verif {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 88px 30px 200px 30px;
  @media only screen and (max-width: 700px) {
    padding: 24px 20px 70px 20px;
  }
  .content {
    max-width: 806.47px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    @media only screen and (max-width: 700px) {
      flex-direction: column;
      align-items: center;
      gap: 30px;
    }
    .left {
      width: 100%;
      @media only screen and (max-width: 700px) {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
    .right {
      width: 100%;
      max-width: 350px;

      .title {
        font-weight: 700;
        font-size: 24px;
        line-height: 45px;
        text-align: center;
        color: #000000;
        margin-bottom: 24px;
      }
      .subtitle {
        font-weight: 700;
        font-size: 16px;
        line-height: 30px;
        text-align: center;
        color: #868889;
      }
      .time {
        margin-bottom: 24px;
        .time_value {
          font-weight: 400;
          font-size: 16px;
          line-height: 30px;
          text-align: center;
          color: #868889;
        }
      }
      .action_resend {
        margin-top: 9px;
        font-weight: 700;
        font-size: 14px;
        line-height: 26px;
        text-align: center;
        color: #000000;
        .resend {
          color: #39c5a3;
          cursor: pointer;
          user-select: none;
        }
      }
      .code_verif {
        display: flex;
        justify-content: center;
        width: 100%;
        align-items: center;
        margin-bottom: 24px;
        margin-top: 24px;

        .styles_react-code-input-container__tpiKG {
          width: 100% !important;
        }
        .styles_react-code-input__CRulA {
          display: flex;
          gap: 24px;
          justify-content: space-between;
          width: 100%;
          align-items: center;
          input {
            border: none !important;
            background: #f5f5f5;
            border-radius: 8px;
          }
        }
      }
    }
  }
}
