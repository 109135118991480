.invoice_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .title {
    font-weight: 700;
    font-size: 14px;
    line-height: 26px;
    color: #000000;
  }
  .value {
    font-weight: 700;
    font-size: 16px;
    line-height: 30px;
    background: linear-gradient(180deg, #fd572a 0%, #f0951d 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
}
