@use "../Collapse/Collapse";
@use "../FilterItem/FilterItem";
.filter {
  width: 100%;
  .divider {
    background: #ffd08f;
    width: 100%;
    height: 1px;
    margin: 24px 0;
  }
  .slider_filter {
    .filter_text {
      font-weight: 700;
      font-size: 18px;
      line-height: 34px;
      color: #000000;
    }
    .ant-slider {
      width: 80%;
      .ant-slider-rail {
        background: #ff9f1c;
        border-radius: 12px;
      }
      .ant-slider-track {
        background: #ff9f1c;
        border-radius: 12px;
      }
      .ant-slider-handle.ant-slider-handle-1 {
        &::after {
          background: #ff9f1c;
          width: 24px;
          height: 24px;
          margin-top: -7px;
          box-shadow: none;
        }
      }
      .ant-slider-handle.ant-slider-handle-2 {
        &::after {
          background: #ff9f1c;
          width: 24px;
          height: 24px;
          margin-top: -7px;
          border: none;
          box-shadow: none;
        }
      }
    }
    .values_slider {
      display: flex;
      justify-content: space-between;
      p {
        font-weight: 700;
        font-size: 14px;
        line-height: 26px;
        text-align: center;
        color: #000000;
      }
    }
  }
  .action_filter {
    display: flex;
    text-align: center;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
    .btn_1 {
      width: 70%;
      padding: 10px 32px;
      // height: 42px;
      background: linear-gradient(180deg, #fd572a 0%, #f0951d 100%);
      border-radius: 8px;
      border: none;
      display: flex;
      text-align: center;
      justify-content: center;
      font-weight: 700;
      font-size: 14px;
      line-height: 26px;

      color: #ffffff;
      cursor: pointer;
    }
    .btn_2 {
      width: 30%;
      padding: 10px 32px;
      // height: 42px;
      background: #bdbdbd;
      border-radius: 8px;
      border: none;
      display: flex;
      text-align: center;
      justify-content: center;
      font-weight: 700;
      font-size: 14px;
      line-height: 26px;

      color: #ffffff;
      cursor: pointer;
    }
  }
}
