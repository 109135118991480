.reset_pwd {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 88px 20px 0px 20px;
  min-height: calc(100vh - 260px);
  .reset_pwd_content {
    max-width: 372px;
    width: 100%;
    .title {
      text-align: center;
      font-weight: 700;
      font-size: 24px;
      line-height: 45px;
      color: #000000;
      margin-bottom: 24px;
    }
    .pwd {
      margin-bottom: 18px;
    }
  }
}
