.contact {
  margin: 170px 24px;
  background: #f2f2f2;
  padding: 0 24px 24px 24px;
  margin-bottom: 55px;
  @media screen and (max-width: 1200px) {
    padding: 24px;
    margin: 70px 24px;
  }
  .items {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 48px;

    .item_1 {
      flex: 1;
      text-align: center;
      min-width: 300px;
      height: 100%;
      @media screen and (max-width: 700px) {
        display: flex;
        text-align: left;
        gap: 15px;
      }
      @media screen and (max-width: 400px) {
        flex-wrap: wrap;
      }
      img {
        width: 175px;
        height: 175px;
        margin-top: -100px;
        @media screen and (max-width: 1200px) {
          margin-top: 0px;
        }
        @media screen and (max-width: 700px) {
          width: 32px;
          height: 32px;
        }
      }
      .title_t {
        font-weight: 700;
        font-size: 20px;
        line-height: 37px;
        color: #000000;
      }
      .content_t {
        font-weight: 400;
        font-size: 16px;
        line-height: 30px;
        color: #000000;
        @media screen and (max-width: 400px) {
          font-size: 12px;
        }
      }
    }

    .item_ar {
      flex: 1;
      text-align: center;
      min-width: 300px;
      height: 100%;
      @media screen and (max-width: 700px) {
        display: flex;
        text-align: right !important;
        gap: 15px;
      }
      @media screen and (max-width: 400px) {
        flex-wrap: wrap;
      }
      img {
        width: 175px;
        height: 175px;
        margin-top: -100px;
        @media screen and (max-width: 1200px) {
          margin-top: 0px;
        }
        @media screen and (max-width: 700px) {
          width: 32px;
          height: 32px;
        }
      }
      .title_t {
        font-weight: 700;
        font-size: 20px;
        line-height: 37px;
        color: #000000;
        @media screen and (max-width: 700px) {
          text-align: right !important;
        }
      }
      .content_t {
        font-weight: 400;
        font-size: 16px;
        line-height: 30px;
        color: #000000;
        @media screen and (max-width: 700px) {
          text-align: right !important;
        }
        @media screen and (max-width: 400px) {
          font-size: 12px;
        }
      }
    }
  }
}
