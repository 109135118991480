.navigation__container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  width: 100%;
  & > .navigation_link {
    display: flex;
    align-items: center;
    gap: 14px;
    & > svg {
      stroke: #707c9a;
    }
  }

  .header__right {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    // gap: 24px;
    flex-direction: column;
    width: 100%;
    @media only screen and (max-width: 1089px) {
      flex-grow: 1;
      justify-content: space-between;
    }
    .item_header {
      font-weight: 400;
      font-size: 16px;
      line-height: 30px;
      color: #868889;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    .sign_up_header {
      background: none;
      border: none;
      gap: 4px;
    }
    .login_header {
      cursor: pointer;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      font-size: 14px;
      line-height: 26px;
      display: flex;
      align-items: center;
      color: #ffffff;
      gap: 10px;
      height: 46px;
      background: linear-gradient(180deg, #fd572a 0%, #f0951d 100%);
      border-radius: 6px;
      padding: 0 24px;
    }
    .lang_header {
      background: none;
      border: none;
      gap: 4px;
    }
    .link_header {
      margin-top: 20px;
    }
    .divider {
      margin: 24px 0;
    }
  }
}
