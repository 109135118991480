.shipping_option {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  align-items: center;
  padding: 14px 16px;
  margin-bottom: 5px;
  .top_option {
    display: flex;
    justify-content: space-between;
    .title_sh {
      font-weight: 700;
      font-size: 12px;
      line-height: 22px;
      color: #000000;
    }
    .value_sh {
      font-weight: 700;
      font-size: 10px;
      line-height: 19px;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      background: linear-gradient(180deg, #fd572a 0%, #f0951d 100%);
      border-radius: 120px;
      padding: 4px 12px;
      min-width: 63px;
      height: 27px;
    }
  }
  .texts {
    font-weight: 700;
    font-size: 12px;
    line-height: 22px;
    color: #868889;
  }
}
.section_collapse {
  min-height: 165px;
}
.other_options {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  align-items: center;
  padding: 14px 16px;
  width: 100%;
  .top_option {
    display: flex;
    justify-content: space-between;
    .value_sh {
      font-weight: 700;
      font-size: 10px;
      line-height: 19px;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      background: linear-gradient(180deg, #fd572a 0%, #f0951d 100%);
      border-radius: 120px;
      padding: 4px 12px;
      min-width: 63px;
      height: 27px;
    }
  }
  .ant-select-selection-placeholder {
    margin-left: 0px;
  }
  .text_collapse {
    font-weight: 700;
    font-size: 12px;
    line-height: 22px;
    color: #000000;
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    user-select: none;
    margin-bottom: 16px;

    .open {
      transform: rotate(180deg);
    }
  }
  .collapse_content {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;
  }
}
