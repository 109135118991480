.close-btn {
  width: 18.42px;
  height: 18.42px;
  cursor: pointer;
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm {
  max-width: none;
  width: 85% !important;
  min-height: 589px;
  padding: 5px 35px 5px 35px;
  @media only screen and (max-width: 1200px) {
    padding: 5px 0px 5px 0px;
  }
  @media only screen and (max-width: 600px) {
    width: 100% !important;
  }

  @media only screen and (min-width: 1500px) {
    width: 1200px !important;
  }
}
.modal-content-product {
  display: grid;
  grid-template-columns: 50% 50%;
  justify-content: center;
  gap: 22px;
  @media only screen and (max-width: 1050px) {
    display: flex;
    flex-direction: column;
    // flex-wrap: wrap;
  }
  // .images-product {
  //   .images {
  //     width: 100%;
  //     height: 100%;
  //   }
  // }
  .model-content {
    width: 100%;
    font-family: "Cairo";
    font-style: normal;
    .modal-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .product-title {
        font-weight: 700;
        font-size: 25.2px;
        line-height: 47px;
        text-align: center;
        color: #000000;
        @media only screen and (max-width: 680px) {
          font-size: 18px;
          line-height: 34px;
        }
      }
      .product-price {
        font-weight: 700;
        font-size: 22.4px;
        line-height: 42px;
        background: linear-gradient(180deg, #fd572a 0%, #f0951d 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        @media only screen and (max-width: 680px) {
          font-size: 16px;
          line-height: 30px;
        }
      }
    }

    .description {
      font-weight: 400;
      font-size: 19px;
      line-height: 37px;
      color: #868889;
      @media only screen and (max-width: 680px) {
        font-size: 14px;
        line-height: 26px;
      }
    }
    .descreption-product {
      display: flex;
      justify-content: space-between;
      .product-Weight-detail {
        display: flex;
        gap: 15px;
        .product-nbr-by-kilo {
          font-weight: 700;
          font-size: 19.6px;
          line-height: 37px;
          text-align: center;
          @media only screen and (max-width: 680px) {
            font-size: 14px;
            line-height: 26px;
          }
        }
      }
      .product-conetent-by-kilo {
        font-weight: 400;
        font-size: 11.2px;
        line-height: 21px;
        text-align: center;
        color: #868889;
      }
    }

    .product-market-detail {
      display: flex;
      gap: 15px;
      .product-Dammam {
        font-weight: 700;
        font-size: 19.6px;
        line-height: 37px;
        text-align: center;
        @media only screen and (max-width: 680px) {
          font-size: 14px;
          line-height: 26px;
        }
      }
    }

    .product-price-detail {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .product-price-description {
        display: flex;
        gap: 30px;
        .product-price {
          font-weight: 700;
          font-size: 22.4px;
          line-height: 42px;
          background: linear-gradient(180deg, #fd572a 0%, #f0951d 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          @media only screen and (max-width: 680px) {
            font-size: 16px;
            line-height: 30px;
          }
        }
      }

      .count-product {
        display: flex;
        gap: 40px;
        @media only screen and (max-width: 680px) {
          gap: 20px;
        }
        .add-product {
          width: 33px;
          height: 33px;
          background: linear-gradient(180deg, #fd572a 0%, #f0951d 100%);
          border-radius: 11px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          user-select: none;
          @media only screen and (max-width: 680px) {
            width: 24px;
            height: 24px;
            border-radius: 8px;
          }
          img {
            width: 16px;
            @media only screen and (max-width: 780px) {
              width: 12px;
            }
          }
        }
        .nbr-product {
          font-weight: 700;
          font-size: 19px;
          line-height: 37px;
          text-align: center;
          color: #000000;
          @media only screen and (max-width: 680px) {
            font-size: 14px;
            line-height: 26px;
          }
        }
        .remove-product {
          width: 33px;
          height: 33px;
          background: linear-gradient(180deg, #fd572a 0%, #f0951d 100%);
          border-radius: 11px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          user-select: none;
          @media only screen and (max-width: 680px) {
            width: 24px;
            height: 24px;
            border-radius: 8px;
          }
          img {
            width: 16px;
            @media only screen and (max-width: 780px) {
              width: 12px;
            }
          }
        }
      }
    }
    .description-nothave-product {
      font-weight: 700;
      font-size: 19.6px;
      line-height: 37px;
      color: #fd2a2a;
      @media only screen and (max-width: 680px) {
        font-size: 14px;
        line-height: 26px;
      }
    }
    .product-price-info {
      background: #f8f8f8;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      padding: 22.4px 0px 0px;
      border-radius: 11px;
      .product-select-price-details {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 0 15px 0 15px;
        .product-select-price-title {
          display: flex;
          text-align: center;
          justify-content: center;
          gap: 20px;
          font-weight: 700;
          font-size: 19px;
          line-height: 37px;
          text-align: center;
          color: #000000;
          cursor: pointer;
          user-select: none;
          @media only screen and (max-width: 680px) {
            font-size: 14px;
            line-height: 26px;
          }
          .icon-detail-product {
            // padding-left: 20px;
            &.icon-detail-rotate-product {
              transition-duration: 100ms;
              transform: rotate(180deg);
              // padding-right: 20px;
            }
          }
        }
        .product-selected-price {
          font-weight: 500;
          font-size: 14px;
          line-height: 26px;
          text-align: center;
          color: #606060;
          @media only screen and (max-width: 680px) {
            font-size: 10px;
            line-height: 19px;
          }
        }
      }
      .product-detail-content {
        width: 95%;
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;
        font-weight: 700;
        font-size: 20px;
        color: #868889;
        @media only screen and (max-width: 1300px) {
          font-size: 14px;
        }
        .name {
          width: 30%;
        }
        .pourcentage {
          width: 15%;
        }
        .price {
          width: 30%;
          display: flex;
          justify-content: flex-end;
        }
      }
      .product-total-price-detail {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 15px 0 15px;
        width: 96%;
        height: 47px;
        background: #ffffff;
        margin-top: 5px;
        .product-total-price-title {
          font-weight: 700;
          font-size: 19.6px;
          line-height: 37px;
          text-align: center;
          color: #000000;
          @media only screen and (max-width: 680px) {
            font-size: 14px;
            line-height: 26px;
          }
        }
        .product-total-price-ors {
          font-weight: 700;
          font-size: 22px;
          line-height: 42px;
          background: linear-gradient(180deg, #fd572a 0%, #f0951d 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          @media only screen and (max-width: 680px) {
            font-size: 16px;
            line-height: 30px;
          }
        }
      }
      .btn-addcart {
        width: 100%;
        outline: none;
        border: none;
        cursor: pointer;
        height: 81px;
        background: linear-gradient(180deg, #fd572a 0%, #f0951d 100%);
        border-radius: 11.2px;
        font-weight: 700;
        font-size: 19px;
        line-height: 37px;
        color: #ffffff;
        margin: 0;
        margin-top: 13px;
        @media only screen and (max-width: 980px) {
          font-size: 14px;
          line-height: 26px;
          height: 58px;
        }
      }
      .product-price-description {
        width: 95%;
        display: flex;
        gap: 16px;
        .product-price-title {
          font-weight: 700;
          font-size: 19px;
          line-height: 37px;
          text-align: center;
          color: #000000;
          @media only screen and (max-width: 680px) {
            font-size: 14px;
            line-height: 26px;
          }
        }
        .product-price {
          font-weight: 700;
          font-size: 22.4px;
          line-height: 42px;
          background: linear-gradient(180deg, #fd572a 0%, #f0951d 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          @media only screen and (max-width: 680px) {
            font-size: 16px;
            line-height: 30px;
          }
        }
      }
    }
  }
}
