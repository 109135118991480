.Collapse_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  cursor: pointer;

  p {
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    text-align: right;
    color: #000000;
  }
}
