.modal_login_antd {
  .ant-modal-content {
    background-color: #ffff !important;
    padding: 32px !important;

    .title {
      text-align: center;
      background: linear-gradient(180deg, #fd572a 0%, #f0951d 100%);
      -webkit-text-fill-color: transparent;
      font-weight: 700;
      font-size: 24px;
      line-height: 45px;
      margin-top: 30px;
      margin-bottom: 15px;
      -webkit-background-clip: text;
    }
    .welcome {
      text-align: center;
      font-weight: 700;
      font-size: 24px;
      line-height: 45px;
      margin-bottom: 15px;
    }
    .text1 {
      text-align: center;
      font-weight: 700;
      font-size: 16px;
      line-height: 30px;
      color: #868889;
      margin-bottom: 15px;
    }
    .forget_pwd {
      font-weight: 700;
      font-size: 14px;
      line-height: 26px;
      /* green */
      color: #39c5a3;
      margin-bottom: 16px;
      cursor: pointer;
      user-select: none;
    }
    .without_cmpt {
      font-weight: 700;
      font-size: 14px;
      line-height: 26px;
      color: #000000;
      margin-top: 9px;
      text-align: center;
      .signup {
        font-weight: 700;
        font-size: 14px;
        line-height: 26px;
        color: #39c5a3;
        cursor: pointer;
      }
    }
    .inputs {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 16px;
    }
  }
}
