.cart_header {
  display: flex;
  gap: 4px;
  position: relative;
  .number_product {
    position: absolute;
    background-color: red;
    border-radius: 50%;
    width: 12px;
    height: 12px;

    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 6px;
    line-height: 11px;
    color: #ffffff;
  }
  .a_r {
    top: 0;
    right: -2px;
  }
  .e_n {
    top: 0;
    left: 15px;
  }
}
